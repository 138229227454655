import React from "react";
import { Card, CardBody } from "@paljs/ui/Card";
import { ButtonLink } from "@paljs/ui/Button";
import { navigate } from "gatsby";
import { styled } from "@mui/material/styles";
import Layout from "../components/Layouts";

import Seo from "../components/SEO";

const ErrorStyle = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  small {
    margin-bottom: 3rem;
  }
  h1 {
    margin-bottom: 0.5rem;
  }
  a {
    max-width: 20rem;
  }
`;

export default function Error() {
  return (
    <Layout>
      <Seo title="404 Page Not Found" />
      <Card>
        <CardBody>
          <ErrorStyle>
            <h1>404 Page Not Found</h1>
            <small>The page you were looking for doesn&apos;t exist</small>
            <ButtonLink
              fullWidth
              appearance="hero"
              onClick={() => navigate(`/${process.env.GATSBY_ADMIN_URL}`)}
              shape="Rectangle"
            >
              Take me home
            </ButtonLink>
          </ErrorStyle>
        </CardBody>
      </Card>
    </Layout>
  );
}
